exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-cips-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/cips/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-cips-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-draje-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/draje/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-draje-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-jelibon-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/jelibon/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-jelibon-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-jole-lokum-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/jöle_lokum/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-jole-lokum-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-kahve-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/kahve/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-kahve-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-kurumeyve-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/kurumeyve/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-kurumeyve-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-kuruyemis-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/kuruyemis/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-kuruyemis-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-lokum-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=/home/safag/experimental/balkuruyemis/content/projects/lokum/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-lokum-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

